body {
  margin: 0;
  color: #333;
}
.highlight {
  background-color: yellow;
  font-weight: bold;
}

.header {
  position: relative;

  background-image: url("../Images/atlas-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  padding: 20px;
  /* border-bottom: 2px solid #fa6125; */
}
.header_div {
  display: flex;
  justify-content: space-between;
}

.header_container {
  background-color: #fcf9f0;
  border-radius: 50px;
  margin: 50px;
  width: fit-content;
  padding: 30px;
}

.result_container {
  background-color: #fff2eb;
  border-radius: 20px;
  margin: 20px;
  padding: 10px;
}
/* Media queries for responsiveness */

/* Add a border around the background image */

h1 {
  color: #e50000;
  font-size: 2.5em;
  font-weight: 600;
}

h2 {
  color: rgb(227, 125, 52);
  font-weight: 600;
}
h3 {
  color: #e50000;
}
h4 {
  color: rgb(227, 125, 52);
}

p {
  margin: 10px 0;
}

.content-section {
  margin-bottom: 40px;
}

.vertical-center {
  margin: 0;
  flex: 0 0 70%;
  top: 50%;
  left: 50%;
}

.search-bar {
  height: 45px;

  width: 60%;
  padding-left: 34px;
  padding-right: 10px;
  border-radius: 5px;
  border-color: #fa713b;
}

button {
  border: none;
  height: 45px;
  background-color: #e37d34;
  color: #fff;
  width: 110px;
  border-radius: 5px;
}

#resultsList li {
  margin: 15px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

#resultsList h1 {
  margin: 0;
  color: #e50000;
  cursor: pointer;
}

#resultsList .content {
  display: none;
  color: #555;
}

.no-results {
  color: red;
  font-size: 1.2em;
  font-weight: bold;
}

/* Media queries */
@media (max-width: 768px) {
  .search-bar {
    width: 70%;
    margin-bottom: 10px;
  }

  button {
    width: 100px;
  }

  h1 {
    font-size: 1.5em;
  }
}
/******************************************************************************************************/

.btn {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.btn-signup {
  background-color: #e37d34;
  color: white !important;
  max-width: 400px;
}
.atlas-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: #fff;
  height: 80px;
}

.atlas-logo {
  padding-left: 10px;
}

.atlas-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.atlas-nav li {
  margin-right: 20px;
}

.atlas-nav a {
  color: #ffffff;
  text-decoration: none;
}

.atlas-hero {
  /* background-image: url("hero-image.jpg"); */
  background-size: cover;
  background-position: center;
  height: 400px;

  justify-content: center;
  align-items: center;
  color: #bf1f1f;
}

.atlas-hero h1 {
  font-size: 48px;
  font-weight: bold;
}

.atlas-hero button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

.atlas-about,
.atlas-vision,
.atlas-mission,
.atlas-what-we-do {
  margin-bottom: 40px;
  margin-left: 20px;
}

.atlas-about h2,
.atlas-vision h2,
.atlas-mission h2,
.atlas-what-we-do h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.atlas-about p,
.atlas-vision p,
.atlas-mission p,
.atlas-what-we-do p {
  font-size: 18px;
  margin-bottom: 20px;
}

.atlas-about ul,
.atlas-vision ul,
.atlas-mission ul,
.atlas-what-we-do ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.atlas-about li,
.atlas-vision li,
.atlas-mission li,
.atlas-what-we-do li {
  margin-bottom: 10px;
}

.atlas-footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.atlas-footer p {
  margin-bottom: 10px;
}
