@import "~@fortawesome/fontawesome-free/css/all.min.css";

.img {
  max-width: 100%;
  background-color: transparent;
}

.a {
  text-decoration: none;
  outline: none;
  color: #444444;
}

.a:hover {
  color: #444;
}

.ul {
  margin-bottom: 0;
  padding-left: 0;
}

.a:hover,
.a:focus,
.input,
.textarea {
  text-decoration: none;
  outline: none;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.cp {
  cursor: pointer;
}

.html,
.body {
  height: 100%;
}

.p {
  margin-bottom: 0px;
  width: 100%;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.hid {
  display: none;
}

.top-mar {
  margin-top: 15px;
}

.h-100 {
  height: 100%;
}

::placeholder {
  color: #747f8a !important;
  font-size: 13px;
  opacity: 0.5 !important;
}

.container-fluid {
  padding: 0px;
}

.strong {
  font-family: "Poppins";
}

.body {
  background-color: #f7f7ff !important;
  font-family: "Poppins";
  color: #6a6a6a;
}

.session-title {
  padding: 30px;
  margin: 0px;
}
.session-title h2 {
  width: 100%;
  text-align: center;
}
.session-title p {
  max-width: 850px;
  text-align: center;
  float: none;
  margin: auto;
}
.session-title span {
  float: right;
  font-style: italic;
}

.inner-title {
  padding: 20px;
  padding-left: 0px;
  margin-bottom: 30px;
}
.inner-title h2 {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-family: "Poppins";
}
.inner-title p {
  width: 100%;
  text-align: center;
}

.page-nav {
  padding: 40px;
  text-align: center;
  padding-top: 160px;
}
.page-nav ul {
  float: none;
  margin: auto;
}
.page-nav h2 {
  font-size: 36px;
  width: 100%;
  color: #444;
}
@media screen and (max-width: 600px) {
  .page-nav h2 {
    font-size: 26px;
  }
}
.page-nav ul li {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 16px;
}
.page-nav ul li i {
  width: 30px;
  text-align: center;
  color: #444;
}
.page-nav ul li a {
  color: #444;
}

.btn-success {
  background-color: #00ab9f;
  border-color: #00ab9f;
}
.btn-success:hover {
  background-color: #00ab9f !important;
  border-color: #00ab9f !important;
}
.btn-success:active {
  background-color: #00ab9f !important;
  border-color: #00ab9f !important;
}
.btn-success:focus {
  background-color: #00ab9f !important;
  border-color: #00ab9f !important;
  box-shadow: none !important;
}

.btn-info {
  background-color: #4f6dcd;
  border-color: #4f6dcd;
}
.btn-info:hover {
  background-color: #4f6dcd !important;
  border-color: #4f6dcd !important;
}
.btn-info:active {
  background-color: #4f6dcd !important;
  border-color: #4f6dcd !important;
}
.btn-info:focus {
  background-color: #4f6dcd !important;
  border-color: #4f6dcd !important;
  box-shadow: none !important;
}

.btn {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
.btn-signup {
  background-color: #ff3131;
  color: white;
}
.form-control:focus {
  box-shadow: none !important;
  border: 2px solid #00a8df;
}

.btn-light {
  background-color: #fff;
  color: #3f3f3f;
}

.collapse.show {
  display: block !important;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid #dc3545 !important;
}

.form-control {
  background-color: #f8f8f8;
  margin-bottom: 20px;
  border-radius: 50px;
}
.form-control:focus {
  background-color: #fff;
  border-color: #ccc;
}

.container {
  max-width: 100%;
}

/* ===================================== Header CSS ================================== */
@media screen and (max-width: 991px) {
  .cdvfdfd {
    padding: 15px;
  }
}

.login-box {
  background-color: #fff;
  float: none;
  margin: auto;
  border-radius: 25px !important;
  -webkit-box-shadow: 0px 0px 72px -10px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px 0px 72px -10px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 0px 72px -10px rgba(0, 0, 0, 0.44);
  border-radius: 5px;
  margin-top: 6%;
}
@media screen and (max-width: 575px) {
  .login-box {
    margin-top: 0px;
  }
}
@media screen and (max-width: 1096px) {
  .login-box {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

.log-det {
  padding: 40px;
}
.log-det .small-logo {
  text-align: center;
  align-items: center;
  color: #c82333;
  font-size: 1.2rem;
}
.log-det .small-logo i {
  font-size: 32px;
  margin-right: 10px;
}
.log-det .dfmn {
  margin-top: 15px;
  font-size: 0.9rem;
}
.log-det h2 {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 40px;
}
.log-det ul {
  float: none;
  margin: auto;
  width: 200px;
}
.log-det ul li {
  float: left;
  padding: 10px;
}
.log-det ul li i {
  padding: 10px;
  border: 1px solid #ccc;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50px;
}
.log-det p {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.log-det .small-info {
  font-size: 0.9rem;
  border-top: 1px solid #cccccc73;
  padding-top: 20px;
  margin-bottom: 5px;
}
.log-det .btn {
  width: 100%;
}
.log-det .text-box-cont {
  max-width: 300px;
  float: none;
  margin: auto;
}
.log-det .text-box-cont .input-group .input-group-prepend span {
  background-color: #f8f8f8;
  border-radius: 0px;
  border-right: 0px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}
.log-det .text-box-cont .input-group .input-group-prepend i {
  color: #777474;
  font-size: 14px;
}
.log-det .text-box-cont .input-group input {
  border-left: 0px;
  border-radius: 0px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}
.log-det .text-box-cont .btn {
  border-radius: 50px;
  padding: 10px 30px;
  margin: auto;
  margin-bottom: 10px;
  font-size: 13px;
}
.log-det .forget-p {
  margin-top: 10px;
  font-size: 0.9rem;
}
.log-det .forget-p span {
  color: #c82333;
  font-weight: 600;
  margin-left: 5px;
}

.box-de {
  background-image: url(../Images/Login.png);
  background-size: 100%;
  padding: 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-repeat: no-repeat;
  background-size: contain;
}
.box-de .inn-cover {
  display: flex;
  background-color: rgba(248, 173, 51, 0.7);

  width: 100%;
  height: 100%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 30px;
}
@media screen and (max-width: 767px) {
  .box-de .inn-cover {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 25px;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .box-de {
    border-bottom-left-radius: 25px;
  }
}
.box-de .ditk-inf {
  margin: auto;
  align-items: center;
  text-align: center;
  color: #fff;
}
.box-de .ditk-inf .small-logo {
  position: relative;
  top: 0px;
  margin-bottom: 20px;
  font-size: 1.3rem;
}
.box-de .ditk-inf .small-logo i {
  font-size: 44px;
}
.box-de .ditk-inf p {
  margin-top: 15px;
  font-size: 0.9rem;
}
.box-de .ditk-inf .btn-outline-light {
  border-radius: 50px;
  box-shadow: none;
  padding: 5px 20px;
  margin-top: 25px;
}
.box-de .small-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
}
.box-de .small-logo i {
  font-size: 22px;
  margin-right: 5px;
}
.box-de .foter-credit {
  position: absolute;
  bottom: 10px;
  right: 6%;
  font-size: 14px;
  padding: 10px;
  color: #fff;
  font-weight: 300;
}
.box-de .foter-credit a {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .box-de .foter-credit {
    display: none;
  }
}

.sup {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .sup-oi {
    margin-top: 40px !important;
  }
}
/*****************************************************/
